.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 5px 10px;
  height: 200px;
  width:100%; /* Change width to 100% for better responsiveness */
 
  margin: 0 auto; /* Center header */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}


.logo1 img {
  height: auto; /* Keep height auto */
  width: 230px; /* Fixed width for logo */
  padding: 20px;
}

.navigation {
  display: flex;
  align-items: center;
  position: relative;
  left:-100px;
}

.nav-link {
  color: white;
  margin-right: 20px;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
  position: relative;
  top:-30px;
}

.nav-link:hover {
  color: #00aaff;
}

.get-started-btn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
  top:-30px;
}

.get-started-btn:hover {
  background-color: white;
  color: black;
}


/* Responsive Styles */
@media (max-width: 1250px) {
  .header {
    flex-direction: column; /* Stack elements on smaller screens */
    height: auto; /* Adjust height */
    width: 1397px;
    padding: 10px;

  }

  .logo1 img {
    max-width: 180px; /* Adjust logo size for tablets */

  }

  .navigation {
    justify-content: center; /* Center navigation on smaller screens */
    margin-top: 10px;
  }

  .nav-link {
    font-size: 14px; 
    position: relative;
    left:100px;/* Slightly smaller font size */
    position: relative;
    left:-140px;
/* Reduce margin between links */
  }

  .get-started-btn {
    padding: 6px 12px; /* Adjust button padding */
    font-size: 14px;
  }
}

/* Responsive Styles */
@media (max-width: 950px) {
  .header {
    flex-direction: column; /* Stack elements on smaller screens */
    height: auto; /* Adjust height */
    width: 875px;
    padding: 10px;
    position: relative;
    left:-140px;
  }

  .logo1 img {
    max-width: 180px; /* Adjust logo size for tablets */
  }

  .navigation {
    justify-content: center; /* Center navigation on smaller screens */
    margin-top: 10px;
  }

  .nav-link {
    font-size: 14px; /* Slightly smaller font size */
    margin-right: 10px; /* Reduce margin between links */
  }

  .get-started-btn {
    padding: 6px 12px; /* Adjust button padding */
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .header {
    flex-direction: column; /* Stack elements on smaller screens */
    height: auto; /* Adjust height */
    width: 460px;
    padding: 10px;
  }

  .logo1 img {
    max-width: 150px; /* Further adjust logo size for mobile */
    position: relative;
    left:90px;
  }

  .nav-link {
    font-size: 12px; /* Smaller font size */
    margin-right: 5px; /* Smaller margin on mobile */
    position: relative;
    left:90px;
  }

  .get-started-btn {
    padding: 4px 8px; /* Adjust button padding */
    font-size: 12px;
    position: relative;
    left:90px;
  }
}





/* General navigation styles */
.navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 10px 20px;
}

/* Styling for the nav links */
.navLink {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 15px;
  transition: color 0.3s ease;
  position: relative;
  top:-20px;
}
.navLink1 {
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 8px 15px;
  transition: color 0.3s ease;
  position: relative;
}

.navLink:hover {
  color: blue; /* Change link color on hover */
}


.get-started-btn:hover {
  background-color: white;
}

/* Make sure the link inside the button is styled correctly */
.get-started-btn .navLink {
  color: white;
  text-decoration: none;
  font-size: 18px;
}
.get-started-btn .navLink1:hover{
  color: #000;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .navigation {
    flex-direction: column;
    align-items: flex-start;
  }

  .navLink {
    padding: 10px 0;
  }

  .get-started-btn {
    margin-top: 10px;
  }
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative; /* Position relative to parent */
  width: 64px;
  height: auto;
  /* position: relative; */
  box-sizing: border-box;
  overflow: visible;
  visibility: inherit;
  background-color: #000;
  z-index: 1;

}

.dropdown-menu {
  display: none; /* Initially hide dropdown */
  position: absolute; /* Position absolutely within the dropdown */
  background-color: #01010105; /* Background color for dropdown */
  border: 1px solid #555555; /* Border for dropdown */
  z-index: 1000; /* Ensure it appears above other elements */
right: 0;
color: aliceblue;
}

.dropdown:hover .dropdown-menu {
  display: block;      /* Show dropdown on hover */
  
}

.dropdown-item {
  display: inline-block; /* Make dropdown items block elements */
  color: white; /* Text color */
 
  text-decoration: none; /* No underline */ 
  transition: var(--trans, color .4s ease 0s);
  text-align: right;
  color: rgb(var(--txt, var(--color_15, color_15)));
  padding-left: 10px;
  padding-top: 10px;
    padding-right: 4px;
    padding-bottom: 5px;

}

.dropdown-item:hover {
  color: #3d92e7; /* Highlight on hover */
  
}



.logo1 img {
    height: auto; /* Keep height auto */
  width: 230px; /* Fixed width for logo */
  padding: 20px;
}

.navbar {
  position: relative;
}

/* Mobile-specific styles */
.menu-icon {
  display: none;  /* Hide the hamburger icon on larger screens */
  cursor: pointer;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
  transition: transform 0.3s ease-in-out;
  position: relative;
  left: 210px;
}

.hamburger-icon span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

/* Full-screen overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9); /* Full-screen dark background */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay.open {
  display: flex; /* Show the overlay when hamburger is clicked */
}

/* Close icon (cross mark) */
.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 3rem; /* Increased size from 2rem to 3rem */
  color: white;
  cursor: pointer;
}


/* Desktop navigation links */
.nav-links-desktop {
  display: none; /* Hide links by default */
  list-style: none;
  display: flex;  /* Show links horizontally */
  gap: 1rem;     /* Space between links */
}

/* Mobile-specific navigation links */
.nav-links {
  list-style: none;
  text-align: center;
}

.nav-links li {
  margin: 1.5rem 0;
}

.nav-links li a,
.nav-links-desktop li a {
  color: white;
  font-size: 1.2rem;  /* Increase font size */
  text-decoration: none;
}

/* Media queries for responsiveness */
@media (max-width: 450px) {

  .header {
    flex-direction: column; /* Stack items vertically */
    height: 150px; /* Adjust height to accommodate content */
    width: 128%;
    background:black;

  }

  

  /* Styling for the nav links */
.navLink {
display: none;
}
.navLink1 {
   display: block;
   font-size: 10px;
}
 
button{
  width: 130px;
  height: 50px;
  position: relative;
  top:100px;
  left:-40x;
  
}
 



  
  .menu-icon {
    display: block; /* Show the hamburger icon on mobile */
  
    position: relative; 
    left:35px;
    top:10px;

  }
  .overlay {
    display: none;
  }

  .overlay.open {
    display: flex;  /* Full-screen menu when open */
  }

  .nav-links-desktop {
    display: none; /* Hide desktop links on mobile */
  }
  .get-started-btn {
   
    font-size: 14px;
    position: relative;
    background-color: black;
    top:20px;
    left:180px;
    
    

  }
}

/* Show desktop links on larger screens */
@media (min-width: 451px) {
  .nav-links-desktop {
    display: flex; /* Show links horizontally */
  }

  .menu-icon {
    display: none; /* Hide hamburger icon on larger screens */
  }
}
