/* Main container styling */
.about-us-container {
    background-color: #272727; /* Background color for the page */
    text-align: center;
    padding: 50px 20px; /* Add padding to adjust for smaller screens */
    color: white; /* Ensure text is visible on dark background */
  }
  
  /* Title styling */
  h1 {
    color: white;
    margin-bottom: 30px;
    font-size: 2.5rem; /* Adjust font size for large displays */
  }
  
  /* Boxes container */
  .about-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px; /* Space between boxes */
  }
  
  /* Styling for the boxes */
  .about-box {
    width: 100%; /* Adjust box width to fit full screen, adjust in media queries for large screens */
    max-width: 1200px; /* Limit the maximum width on larger displays */
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #020202; /* Background for all boxes */
  }
  
  /* Flex layout inside the box */
  .box-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  }
  
  /* Image styling */
  .about-img {
    width: 40%;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover; /* Ensures image scales properly */
  }
  
  /* Text content styling */
  .text-content {
    width: 55%;
    text-align: left;
    color: white;
    line-height: 1.6; /* Better readability */
  }
  
  /* Make the boxes alternate background colors */
  .about-box:nth-child(even) {
    background-color: #333333; /* Slightly different background for alternating boxes */
  }
  
/* Contact-specific styling */
.contact-content {
    display: flex;
    justify-content: space-between; /* Ensures left and right alignment */
    align-items: flex-start; /* Aligns content at the top */
    width: 100%;
 
  }
  
  .contact-left {
    text-align: left; /* Align text to the left for the heading and description */
    position: relative;
    height: 100px;
    
  }
  
  .contact-right {
    text-align: right; /* Align email and phone number to the right */
    font-size: 1rem;
  }
  




  /* Responsive design for tablets and smaller screens */
  @media (max-width: 1024px) {
    h1 {
      font-size: 2rem; /* Adjust font size for tablets */
    }
  
    .about-box {
      padding: 15px; /* Reduce padding for tablets */
    }
  
    .about-img {
      width: 45%; /* Slightly smaller image for tablets */
    }
  
    .text-content {
      width: 50%; /* Adjust text width for tablets */
    }
  }
  
  /* Responsive design for mobile devices */
  @media (max-width: 768px) {
    .about-box {
      flex-direction: column; /* Stack image and text vertically on smaller screens */
      padding: 20px; /* Extra padding for readability */
    }
  
    .about-img {
      width: 100%; /* Full width image for small screens */
      margin: 0 0 20px 0; /* Adjust margin for smaller screens */
    }
  
    .text-content {
      width: 100%; /* Full width text for small screens */
      text-align: center;
    }
  }
  
  /* Responsive design for very small mobile devices */
  @media (max-width: 480px) {
    h1 {
      font-size: 1.8rem; /* Smaller font size for very small devices */
    }
  
    .about-box {
      padding: 10px; /* Reduce padding for smaller devices */
    }
  
    .about-img {
      margin-bottom: 15px; /* Adjust margin for very small screens */
    }
  
    .text-content {
      font-size: 0.9rem; /* Adjust text size for readability on small screens */
    }
  }
  

  /* Ensuring proper layout for smaller screens */
@media (max-width: 768px) {
    .contact-content {
      flex-direction: column; /* Stack content vertically on smaller screens */
    }
  
    .contact-left, .contact-right {
      text-align: center; /* Center content for mobile views */
      width: 100%; /* Full width for each section */
      margin-bottom: 10px; /* Add spacing between left and right content */
    }
  }