body {
    background-color: black;
    margin: 0;
    padding: 0;
}

/* Wrapper for the entire component */
.get-started-wrapper {
    display: flex;
    flex-direction: column;
    background-color: black;
    min-height: 100vh;
    overflow: hidden;
}

/* Image container */
.image-container {
    height: 80vh;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

/* Background image styling */
.background-image {
    background-image: url('./assets/Get_Started.png');
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    z-index: 0;
}

.background-image.scrolled {
    transform: scale(1.1);
}

.text-container1 {
    position: relative;
    z-index: 2;
    padding: 20px;
    color: white;
    flex: 1;
    max-width: 3400px;
    margin-left: auto;
    margin-right: auto;
    top: -30px;
}

.get-started-heading {
    font-size: 80px;
}

.get-started-subheading {
    font-size: 30px;
}

.info-container {
    background-color: black;
    padding: 40px 20px; /* Added more padding for height extension */
    margin: 0 auto; /* Center the container */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid white;
    position: relative;
    z-index: 3;
    max-width: 1100px; /* Limit the width on larger screens */
    width: 90%; /* Responsive width */
    min-height: 100vh; /* Ensures it fills the viewport height */
    max-width:1200px ;
}

/* Text container for side-by-side layout */
.text-container {
    position: relative;
    z-index: 2;
    padding: 10px;
    color: white;
    flex: 0 0 30%;
    margin-right: 10px;
    max-width: 100%;
    padding-right: 20px;
}

.text-container p {
    margin-top: 160px;
    text-align: left;
    margin-left: 20px;
}

/* Form container next to text container */
.form-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    max-width: 100%;
   margin-left: 200px;
   margin-right: 50px;
   margin-left: 50px;
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

/* Divider between form fields */
.divider {
    width: 1px; /* Line width */
    background-color: white; /* Line color */
    height: auto; /* Set to auto to allow it to adjust based on content */
    margin: 0 20px; /* Margin on sides of the divider */
    align-self: stretch; /* Make the divider fill the height of the container */
    margin-top: -40px;
    margin-bottom:-40px ;
}

.info-box {
    margin-top: 20px;
    color: white;
    font-size: 1rem; 
}

/* Form row styling */
.form-row {
    display: flex;
    justify-content:  space-between;
    margin-bottom: 15px;
    margin-top: 20px;
}

/* Form group styling */
.form-group {
    flex: 1;
    margin: 0 10px;
    text-align: left;
}

.form-group:last-child {
    margin-right: 0;
}

.form-group label {
    color: white;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 15px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
}

/* Button styling */
.contact-us-btn {
    background-color: #00ACFF; /* Green background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 15px 32px; /* Padding for the button */
    text-align: center; /* Center the text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Aligns the button inline */
    font-size: 18px; /* Font size */
    margin: 10px 0; /* Add space above and below */
    cursor: pointer; /* Pointer on hover */
    border-radius: 5px; /* Rounded corners */
    white-space: nowrap; /* Prevent line breaks */
    transition: background-color 0.3s ease; /* Smooth hover effect */
    margin-top: 20px;
}

.contact-us-btn:hover {
    background-color: #000000;
    border: 1px solid white;
}



header {
    position: relative;
    z-index: 2;
}

footer {
    position: relative;
    z-index: 2;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .info-container {
        flex-direction: column;
        padding: 10px;
        max-width: 100%; /* Make the container take full width */
    }

    .text-container,
    .form-container {
        max-width: 100%;
        margin: 0 auto;
    }

    .form-row {
        flex-direction: column;
    }

    .form-group {
        margin: 0 0 15px;
    }

    .background-image.scrolled {
        transform: scale(1.05);
    }
}

/* Additional styles for extra small screens */
@media (max-width: 480px) {
    .get-started-heading {
        font-size: 50px;
    }

    .get-started-subheading {
        font-size: 24px;
    }

    .contact-us-btn {
        padding: 10px 50px;
        font-size: 1rem;
    }
}
