/* Footer container */
.footer {
  background-color:black;
  padding: 40px 0;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%; /* Full width */
  position: relative;
  flex-shrink: 0; /* Prevents shrinking */
  z-index: 1;
}

.footercontainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footersection {
  flex: 1;
  padding: 20px;
  min-width: 200px;
  max-width: 300px;
}

.footerlogo {
  width: 120px;
  margin-bottom: 15px;
}

.contactinfo {
  margin: 5px 0;
  font-size: 14px;
}

.footersectiontitle {
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #ECF0F1;
}

.footersectionul {
  list-style: none;
  padding: 0;
}

.footersectionli {
  margin: 10px 0;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
}

.footersectionli:hover {
  color: #1ABC9C;
}

.buttonsubscribe {
  background-color: #1ABC9C;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.footerbutton{
  background-color: #1ABC9C;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  height: 50px;

  transition: background-color 0.3s;

}

.footerbutton:hover, .buttonsubscribe:hover {
  background-color: #16A085;
}

.footerinput {
  padding: 10px;
  border: 3px solid white;
  margin-top: 10px;
  width: 80%;
  background-color: transparent;
  border-radius: 5px;
}

.footericon {
  margin: 10px 0;
}

.footericon img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s;
}

.footericon img:hover {
  transform: scale(1.2);
}

.footerpara {
  font-size: 12px;
  margin-top: 10px;
  color: #BDC3C7;
}



@media (max-width: 450px) {

  .footer{
     width: 100%;
     
  }
  .footerlogo {
    margin: 0 auto;
  }

  .footerbutton{
    position: relative;
    top:-15px;
  }
  .buttonsubscribe
  {
    position: relative;
    top:1px;
  }
}
