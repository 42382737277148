.community-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-size: 22px;
  margin: 18px;
}

.community-intro {
  width: 100%; /* Full width for responsiveness */
  max-width: 1520px; /* Max width for larger screens */
  height: 260px;
  background-color: #000000; /* Background color */
  color: #333; /* Text color */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.community-images {
  width: 100%; /* Full width for responsiveness */
  max-width: 1580px; /* Max width for larger screens */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; /* Space between rows */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.image-container {
  width: calc(33.33% - 60px); /* Adjust for responsive design */
  height: auto; /* Allow height to adjust based on content */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 30px; /* Margin for spacing */
}

/* Responsive adjustments for image containers */
@media (max-width: 1200px) {
  .image-container {
    width: calc(50% - 40px); /* Two columns on medium screens */
  }
}

@media (max-width: 768px) {
  .image-container {
    width: calc(100% - 30px); /* One column on small screens */
    margin: 15px; /* Less margin on smaller screens */
  }

  .community-intro {
    height: auto; /* Allow intro height to adjust */
    padding: 20px; /* Padding adjustments for smaller screens */
  }
}

.image-container img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  object-position: center; /* Center the image */
}

.image-container p {
  font-size: 20px;
  margin-top: 10px;
}

.image-container p:nth-of-type(2) { /* Target the second paragraph */
  font-size: 16px; /* Smaller font size */
  color: #ccc; /* Change color if desired */
  margin-top: 5px; /* Less margin for additional info */
}

.thank-you {
  margin-top: 20px;
  text-align: center;
}

.community-partners {
  width: 100%; /* Full width for responsiveness */
  max-width: 1520px; /* Max width for larger screens */
  height: auto; /* Allow height to adjust based on content */
  position: relative; /* Allows for absolute positioning of children */
  margin-top: 20px;
  text-align: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.logo-container img {
  margin: 0 20px; /* Space between logos */
  max-width: 100px; /* Limit logo width for better scaling */
  height: auto; /* Maintain aspect ratio */
  width: 150%; /* Make it responsive */

}

h1, h2 {
  margin: 0;
  padding: 0;
  color: white;
}

p {
  color: white;
  padding: 23px;
}

/* Media queries for headings and paragraphs on smaller screens */
@media (max-width: 768px) {
  h1 {
    font-size: 24px; /* Adjust heading size for small screens */
  }
  h2 {
    font-size: 20px; /* Adjust subheading size for small screens */
  }
  p {
    font-size: 18px; /* Adjust paragraph size for small screens */
  }
}
