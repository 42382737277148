/* Ensure the page stretches to full viewport height */
html, body {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Page container will take full height */
.page-container {
  display: flex;
  flex-direction: column;

}

/* Content wrap will grow to take up remaining space */
.content-wrap {
  flex-grow: 1;
}

/* Footer at the bottom */
footer {
  background-color: #2C3E50;
  padding: 40px 0;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
  width: 100%;
}
