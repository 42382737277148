/* Vision.css */
.vision-page {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}

.vision-container {
  text-align: center;
  padding: 5vh 2vw;
  background-image: url('/src/assets/background image.png');
  background-size: cover;
  background-position: center;
  color: white;
  z-index: 0;
}

.vision-container h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.vision-container p {
  font-size: 1.2rem;
  max-width: 80vw;
  margin: 0 auto;
}

.vision-story,
.vision-story1 {
  padding: 5vh 2vw;
  background-color: transparent;
  display: flex;
  justify-content: space-between; /* Space between elements for row layout */
  align-items: center;
  height: auto; /* Set auto height for better responsiveness */
}

/* Our Story sections */
.Ourstory {
  
  background-image: url('/src/assets/Our Story.png');
  background-size: cover;
  height: 400px; /* Adjusted for better mobile responsiveness */
  width: 45%; /* Display in row and share space equally */
  max-width: 750px; /* Limit max width */
  background-position: center;
  color: #fff;
  margin: 20px 0; /* Add margin for spacing */

}
.Ourstory1 {
background-image: url('/src/assets/Experienced Leadership.png');
background-size: cover;
height: 400px; /* Adjusted for better mobile responsiveness */
width: 45%; /* Display in row and share space equally */
max-width: 750px; /* Limit max width */
background-position: center;
color: #fff;
margin: 20px 0; /* Add margin for spacing */
position: relative;
left:1000px;
}

.Ourstory1 {
  position: relative;
  left: 1000px; /* Specific positioning for large screens */
}

.Ourstorypara {
  position: relative;
  left: 1000px;
}

.Ourstorypara1 {
  position: relative;
  left: -1000px;
}

.storyhead, .storyhead1 {
  font-size: 2rem;
  margin: 20px 0;
  position: relative;
  left: 200px;
  top: 200px;
}

/* Responsive design for tablets */
@media (max-width: 1024px) {

  .vision-story,
.vision-story1 {
  padding: 5vh 2vw;
  background-color: transparent;
  display: flex;
  justify-content: space-between; /* Space between elements for row layout */
  align-items: center;
 /* Set auto height for better responsiveness */
}
  .vision-container h1 {
    font-size: 2.5rem;
  }

  .vision-container p {
    font-size: 1.1rem;
    max-width: 90vw;
  }

  .Ourstory, .Ourstory1 {
    width: 100%; /* Full width on tablet */
    height: 400px; /* Adjust height */
    max-width: 100%;
    margin: 20px 0; /* Keep margin */
  }

  .Ourstory1 {
    left: 0; /* Reset left positioning */
  }

  .Ourstorypara, .Ourstorypara1 {
    left: 0; /* Reset left positioning */
  }

  .storyhead, .storyhead1 {
    left: 0;
    top: 0;
    text-align: center; /* Center align text on smaller screens */
  }
}

/* Responsive design for mobile */
@media (max-width: 768px) {

  .vision-story,
  .vision-story1 {
    padding: 5vh 2vw;
    background-color: transparent;
    display: flex;
    justify-content: space-between; /* Space between elements for row layout */
    align-items: center;
    height: 400px; /* Set auto height for better responsiveness */
  }

  .vision-container h1 {
    font-size: 2rem;
  }

  .vision-container p {
    font-size: 1rem;
    max-width: 95vw;
  }

  .Ourstory, .Ourstory1 {
    width: 100%; /* Full width for mobile */
    height: 250px; /* Smaller height for mobile */
    max-width: 100%;
    margin: 15px 0; /* Keep margin smaller */
  }

  .Ourstory1 {
    left: 0; /* Reset left positioning */
  }

  .Ourstorypara, .Ourstorypara1 {
    left: 0; /* Reset left positioning */
  }

  .storyhead, .storyhead1 {
    font-size: 1.5rem; /* Reduce font size */
    left: 0;
    top: 0;
    text-align: center; /* Center align text */
  }
}
