.solutions-container {
  position: relative;
  padding: 20px;
  color: white;
  min-height: 100vh;
  overflow: hidden;
}

.background-image {
  background-image: url(/src/assets/background\ image.png);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease;
  height: 100vh;
  background-repeat: no-repeat;
  z-index: 1;
}

.background-image.scrolled {
  transform: scale(1.3);
}

.welcome-title {
  text-align: left;
  font-size: 65px;
  line-height: 1.5em;
  margin-bottom: 120px;
  animation: slideInFromRight 1s forwards;
  padding-left: 70px;
  position: relative;
  z-index: 2;
}

.background-box {
  background-color: black;
  padding: 80px;
  border: 3px solid rgb(20, 18, 18);
  position: relative;
  z-index: 1;
}

.second-box {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.left-box {
  background-image: url(/src/assets/Partner\ with\ Us\ for\ Success.png);
  width: 40%;
  min-height: 500px; /* Adjusted height for flexibility */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.4em;
  text-align: center;
  font-size: 30px;
  color: white;
  padding-top: 140px;
  border: 1px solid white;
  position: relative;
  z-index: 2;
}

.partner-title {
  font-size: 30px;
  text-align: center;
  padding-bottom: 40cap; /* Fixed padding */
}

.right-box {
  background-color: black;
  width: 60%;
  min-height: 500px; /* Adjusted height for flexibility */
  padding: 20px;
  border: 1px solid white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
}

.description {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;
  line-height: 1.8em;
  width: 60%;
  margin: 0 auto;
}

.services-list {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.services-list li {
  font-size: 20px;
  margin: 5px 0;
  color: white;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .second-box {
    flex-direction: column;
    align-items: center;
  }

  .left-box,
  .right-box {
    width: 100%;
    min-height: auto;
  }

  .description,
  .services-list {
    width: 100%;
  }
}
