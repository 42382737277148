
/* Global styles for HTML and body */
html, body {
  width: 100%; /* Ensure body takes full width */
  height: 100%; /* Set height to 100% */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.aos-animate {
  opacity: 1 !important;  /* Ensure the content stays visible */
  transform: translateY(0) !important;  /* Prevent any unwanted movement */
}


.home-page {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: flex-start; /* Start content from the top */
  padding: 20px;
  background: linear-gradient(135deg, #000000, #1c1c1c); /* Adds a subtle gradient */
  color: #ffffff; /* White text for contrast */
  min-height: 100vh; /* Cover full viewport height */
  width: 100vw; /* Set width to full viewport width */
  position: relative; /* Ensure relative positioning */
  left: 50%; /* Shift left */
  right: 50%; /* Shift right */
  margin-left: -50vw; /* Offset left by half of viewport width */
  margin-right: -50vw; /* Offset right by half of viewport width */
  border-radius: 10px; /* Slightly rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Adds a nice shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth animation for hover effects */
}

.home-container {
  background-image: url('./assets/img_back.png'); /* Ensure the path is correct */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  width: 100%; /* Full width of the parent */
  height: 600px; /* Define height to make sure the image is visible */
  
  position: relative; /* Relative positioning to control child elements */
  
  margin: 0; /* Reset margin if necessary */
  padding: 0; /* Reset padding if necessary */
  display: block;
}

.head1 {
  font-size: 3rem; /* Larger size for headings */
  margin-bottom: 10px; /* Spacing below */
  color: blue; /* Desired color */
}

.head2 {
  font-size: 2.5rem; /* Larger size for sub-headings */
  margin-bottom: 5px; /* Spacing below */
  color: blue; /* Desired color */
}

.head3 {
  font-size: 2rem; /* Larger size for paragraph text */
  color: #ffffff; /* Desired color */
}

/* Media queries for responsive adjustments */
@media (max-width: 600px) { /* Mobile devices */
  .head1 {
      font-size: 2.2rem; /* Adjusted for mobile */
  }
  .head2 {
      font-size: 1.8rem; /* Adjusted for mobile */
  }
  .head3 {
      font-size: 1.4rem; /* Adjusted for mobile */
  }
}

@media (min-width: 601px) and (max-width: 768px) { /* Tablets */
  .head1 {
      font-size: 2.5rem; /* Adjusted for tablets */
  }
  .head2 {
      font-size: 2rem; /* Adjusted for tablets */
  }
  .head3 {
      font-size: 1.6rem; /* Adjusted for tablets */
  }
}

@media (min-width: 769px) and (max-width: 1024px) { /* Small laptops */
  .head1 {
      font-size: 3rem; /* Adjusted for small laptops */
  }
  .head2 {
      font-size: 2.5rem; /* Adjusted for small laptops */
  }
  .head3 {
      font-size: 2rem; /* Adjusted for small laptops */
  }
}

@media (min-width: 1025px) { /* Large laptops and desktops */
  .head1 {
      font-size: 3.5rem; /* Larger size for large screens */
  }
  .head2 {
      font-size: 3rem; /* Larger size for large screens */
  }
  .head3 {
      font-size: 2.5rem; /* Larger size for large screens */
  }
}
/* Main container styles */
.content2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  background-color: black;
  margin: 20px 0;
  color: #FFFFFF;
}

.leftSection {
  background-image: url('./assets/img.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  padding: 20px;
  display: flex;
  align-items: center;
  height: 830px;
}

.leftcontainer {
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.heading1 {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 20px;
}

.description1 {
  font-size: 1.2rem;
  line-height: 1.6;
  color: white;
}

.rightSection {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.serviceCard {
  padding: 20px;
  background-color: #222;
  border-radius: 8px;
  border: 1px solid #555;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.serviceCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 2rem;
  margin-right: 10px;
  display: inline-block;
}

.subheading {
  font-size: 1.5rem;
  color: #00aaff;
  margin-bottom: 10px;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  color: #ddd;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .content2 {
    flex-direction: column;
    padding: 10px;
  }

  .leftSection {
    height: auto;
    padding: 10px;
    text-align: center;
  }

  .rightSection {
    padding: 10px;
    width: 100%;
  }

  .heading1 {
    font-size: 2rem;
  }

  .description1 {
    font-size: 1rem;
  }

  .serviceCard {
    padding: 15px;
    width: 100%;
    margin-right: 35px; /* Move the serviceCard 15px to the left */
  }

  .icon {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 1.25rem;
  }
}


/* Main container for inner sections */
.container3home {
  display: flex; /* Use flexbox for layout */
  justify-content: space-around; /* Space between left and right sections */
  align-items: center; /* Center items vertically */
  height: 100vh; /* Full viewport height */
  padding: 20px; /* Optional padding */
  box-sizing: border-box; /* Include padding in height/width calculations */
}

/* Left section styles */
.innercontainerleft {
  flex: 1; /* Take up remaining space */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center content vertically */
  padding: 20px; /* Optional padding */
  z-index: 2; /* Ensure it appears above other elements */
}

/* Right section styles */
.innercontainerright {
  flex: 1; /* Take up remaining space */
  position: relative; /* Needed for absolute positioning of video */
  overflow: hidden; /* Hide overflow from video */
}

/* Button styling */
.button1 {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none; /* Remove underline from link */
}

/* Button hover effects */
.button1:hover {
  background-color: rgba(200, 200, 200, 0.8); /* Change background on hover */
}

/* Partnered with section styles */
.partnercontainer {
  text-align: center; /* Center text */
  margin: 50px 0; /* Space between sections */
}

/* Partner home image styles */
.partnerhome {
  background-image: url('/src/assets/5.png'); /* Ensure this path is correct */
  background-position: center;
  background-repeat: no-repeat;
  height: 350px;
  width: 100%;
  position: relative;
  z-index: 1; /* Ensure it appears below other content */
  margin: 0 auto; /* Center the image */
}

/* Optional media queries for responsiveness */
@media (max-width: 768px) {
  .container3home {
    flex-direction: column; /* Stack left and right sections on smaller screens */
    height: auto; /* Allow height to adjust */
  }

  .innercontainerleft,
  .innercontainerright {
    width: 100%; /* Full width on mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .partnerhome {
    height: 250px; /* Adjust height for mobile */
  }
}

/* Optional media queries for responsiveness */
@media (max-width: 768px) {
  .container3home {
    flex-direction: column; /* Stack left and right sections on smaller screens */
    height: auto; /* Allow height to adjust */
  }

  .innercontainerleft,
  .innercontainerright {
    width: 100%; /* Full width on mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .partnerhome {
    height: 250px; /* Adjust height for mobile */
  }
  
.serviceCard {

  background-color: #222;
  border-radius: 8px;
  border: 1px solid #555;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  left:-60px;
  padding: 50px;


}

.leftSection{
  position: relative;
  left:-20px;
}

.innercontainerleft{
  position: relative;
  left:-15px;
}
.home-container{
  position: relative;
  left:-13px;
}
}

